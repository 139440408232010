<template>
	<div>
		<pui-datatable
			v-if="this.$store.getters.getSession.profiles.includes('BEOP')"
			:modelName="modelName"
			:parentModelName="parentModelName"
			:parentModel="parentModel"
			:externalFilter="externalFilter"
			:masterDetail="masterDetail"
			:actions="actions"
			:showMasterDetailFilterListBtn="true"
			:showMasterDetailFilterBtn="true"
			:showMasterDetailSortBtn="true"
			:showMasterDetailConfBtn="true"
			:showCreateBtn="false"
			:showDeleteBtn="false"
		></pui-datatable>
		<pui-datatable
			v-else
			:modelName="modelName"
			:parentModelName="parentModelName"
			:parentModel="parentModel"
			:externalFilter="externalFilter"
			:masterDetail="masterDetail"
			:actions="actions"
			:showMasterDetailFilterListBtn="true"
			:showMasterDetailFilterBtn="true"
			:showMasterDetailSortBtn="true"
			:showMasterDetailConfBtn="true"
			:showCreateBtn="showCreateBtn"
			:showDeleteBtn="showDeleteBtn"
		></pui-datatable>
		<portcallberths-modals :modelName="modelName"></portcallberths-modals>
	</div>
</template>

<script>
import portcallberthsActions from './PortcallberthsActions';
import portcallberthsModals from './PortcallberthsModals.vue';
import PuiGridDetailMixin from 'pui9-mixins/PuiGridDetailMixin';

export default {
	name: 'portcallberths-grid',
	mixins: [PuiGridDetailMixin],
	components: {
		'portcallberths-modals': portcallberthsModals
	},
	data() {
		return {
			modelName: 'portcallberths',
			actions: portcallberthsActions.gridactions,
			modelColumnDefs: {}
		};
	},
	methods: {
		checkPortCall() {
			if (this.parentModel.emergencyProcess != null) {
				const emergencyLength = this.parentModel.emergencyProcess.length - 1;
				if (this.parentModel.emergencyProcess[emergencyLength].statuscode === 'O') {
					return false;
				}
			}
			if (this.parentModel.portcallstatus == 0 || this.parentModel.portcallstatus == 3) {
				return false;
			} else {
				return true;
			}
		}
	}
};
</script>
